<template>
  <div>
    <b-card>
      <b-row>
        <!-- Field: Name -->
        <b-col
          cols="12"
          md="6"
          lg="6"
          class="mb-1"
        >
          <!-- Role Name -->
          <b-form-group
            label-cols-lg="4"
            label="Role Name:"
            label-for="input-default"
            style="font-size: 1.286rem;"
          >
            <b-form-input
              id="input-default"
              style="font-size: 1.286rem;"
              v-model="userDataInfo.name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Name -->
        <b-col
          cols="12"
          md="6"
          lg="6"
          class="mb-1"
        >
          <b-form-group
            label-cols-lg="4"
            label="Status:"
            label-for="input-default"
            style="font-size: 1.280rem;"
          >
            <v-select
              v-model="userDataInfo.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              value="male"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Dashboards
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.dashboard_view"
              :true-value="1"
              :false-value="0"
            >
              Dashboards View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.dashboard_full_view"
              :true-value="1"
              :false-value="0"
            >
              Dashboards Full View
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Report Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.patrol_report_view"
              :true-value="1"
              :false-value="0"
            >
              Patrol Summary View
            </b-form-checkbox>
          </b-col>
          <!-- <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.patrol_report_view"
              :true-value="1"
              :false-value="0"
            >
              Patrol Summary Export Excel
            </b-form-checkbox>
          </b-col> -->
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Area Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.area_view"
              :true-value="1"
              :false-value="0"
            >
              Area View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.area_create"
              :true-value="1"
              :false-value="0"
            >
              Area Create
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.area_edit"
              :true-value="1"
              :false-value="0"
            >
              Area Edit
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Checkpoint Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.checkpoint_view"
              :true-value="1"
              :false-value="0"
            >
              Checkpoint View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.checkpoint_create"
              :true-value="1"
              :false-value="0"
            >
              Checkpoint Create
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.checkpoint_edit"
              :true-value="1"
              :false-value="0"
            >
              Checkpoint Edit
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          User Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.user_view"
              :true-value="1"
              :false-value="0"
            >
              User View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.user_create"
              :true-value="1"
              :false-value="0"
            >
              User Create
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.user_edit"
              :true-value="1"
              :false-value="0"
            >
              User Edit
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Guard Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.guard_view"
              :true-value="1"
              :false-value="0"
            >
              Guard View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.guard_create"
              :true-value="1"
              :false-value="0"
            >
              Guard Create
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.guard_edit"
              :true-value="1"
              :false-value="0"
            >
              Guard Edit
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <h4 class="mb-0">
          Role Management
        </h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1 mb-2">
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.role_view"
              :true-value="1"
              :false-value="0"
            >
              Role View
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.role_create"
              :true-value="1"
              :false-value="0"
            >
              Role Create
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-checkbox
              v-model="roles.role_edit"
              :true-value="1"
              :false-value="0"
            >
              Role Edit
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="isSubmitted"
            @click="submitUser"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
// import {
//   BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard,
// } from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import store from '@/store'
// import userStoreModule from '@/views/apps/settings/users/userStoreModule'
// import { onUnmounted, ref } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BFormCheckbox, vSelect,
  },
  setup() {
    // Use toast
    const toast = useToast()

    const userDataInfo = ref({
      name: '',
      status: '',
    })

    const isSubmitted = false

    const roles = ref({
      dashboard_view: false,
      dashboard_full_view: false,
      patrol_report_view: false,
      area_view: false,
      area_create: false,
      area_edit: false,

      checkpoint_view: false,
      checkpoint_create: false,
      checkpoint_edit: false,

      user_view: false,
      user_create: false,
      user_edit: false,

      guard_view: false,
      guard_create: false,
      guard_edit: false,

      role_view: false,
      role_create: false,
      role_edit: false,
    })

    const ability = ref([])

    const statusOptions = [
      { label: 'Enabled', value: 1 },
      { label: 'Disabled', value: 0 },
    ]

    return {
      userDataInfo,
      roles,
      statusOptions,
      ability,
      toast,
      isSubmitted,
    }
  },
  mounted() {
    axios
      .post(`${this.$store.state.customConfig.baseURL}/web/role/getbyid`, {
        apikey: this.$store.state.customConfig.token,
        id: this.$route.params.id,
      }, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      .then(responseUser => {
        console.log(responseUser)
        const response = responseUser.data.data
        console.log(response)
        this.userDataInfo.name = response.name
        this.userDataInfo.status = Number(response.status)
        response.data.ability.forEach(element => {
          if (element.subject === 'dashboard' && element.action === 'view') {
            this.roles.dashboard_view = true
          }
          if (element.subject === 'dashboardFullView' && element.action === 'view') {
            this.roles.dashboard_full_view = true
          }
          if (element.subject === 'report' && element.action === 'view') {
            this.roles.patrol_report_view = true
          }
          if (element.subject === 'area' && element.action === 'view') {
            this.roles.area_view = true
          }
          if (element.subject === 'area' && element.action === 'create') {
            this.roles.area_create = true
          }
          if (element.subject === 'area' && element.action === 'edit') {
            this.roles.area_edit = true
          }
          if (element.subject === 'checkpoint' && element.action === 'view') {
            this.roles.checkpoint_view = true
          }
          if (element.subject === 'checkpoint' && element.action === 'create') {
            this.roles.checkpoint_create = true
          }
          if (element.subject === 'checkpoint' && element.action === 'edit') {
            this.roles.checkpoint_edit = true
          }
          if (element.subject === 'guard' && element.action === 'view') {
            this.roles.guard_view = true
          }
          if (element.subject === 'guard' && element.action === 'create') {
            this.roles.guard_create = true
          }
          if (element.subject === 'guard' && element.action === 'edit') {
            this.roles.guard_edit = true
          }
          if (element.subject === 'monitoring_user' && element.action === 'view') {
            this.roles.user_view = true
          }
          if (element.subject === 'monitoring_user' && element.action === 'create') {
            this.roles.user_create = true
          }
          if (element.subject === 'monitoring_user' && element.action === 'edit') {
            this.roles.user_edit = true
          }
          if (element.subject === 'monitoring_role' && element.action === 'view') {
            this.roles.role_view = true
          }
          if (element.subject === 'monitoring_role' && element.action === 'create') {
            this.roles.role_create = true
          }
          if (element.subject === 'monitoring_role' && element.action === 'edit') {
            this.roles.role_edit = true
          }
        })
      })
      .catch(error => {
        console.log(error)
        // this.$refs.loginForm.setErrors(error.response.data.error)
      })
  },
  methods: {
    submitUser() {
      this.isSubmitted = true
      let obj = {
        action: '',
        subject: '',
      }
      this.ability = []
      if (this.roles.dashboard_view === true) {
        obj.action = 'view'
        obj.subject = 'dashboard'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.dashboard_full_view === true) {
        obj.action = 'view'
        obj.subject = 'dashboardFullView'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.patrol_report_view === true) {
        obj.action = 'view'
        obj.subject = 'report'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.area_view === true) {
        obj.action = 'view'
        obj.subject = 'area'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.area_create === true) {
        obj.action = 'create'
        obj.subject = 'area'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.area_edit === true) {
        obj.action = 'edit'
        obj.subject = 'area'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.checkpoint_view === true) {
        obj.action = 'view'
        obj.subject = 'checkpoint'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.checkpoint_create === true) {
        obj.action = 'create'
        obj.subject = 'checkpoint'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.checkpoint_edit === true) {
        obj.action = 'edit'
        obj.subject = 'checkpoint'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.guard_view === true) {
        obj.action = 'view'
        obj.subject = 'guard'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.guard_create === true) {
        obj.action = 'create'
        obj.subject = 'guard'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.guard_edit === true) {
        obj.action = 'edit'
        obj.subject = 'guard'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.user_view === true) {
        obj.action = 'view'
        obj.subject = 'monitoring_user'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.user_create === true) {
        obj.action = 'create'
        obj.subject = 'monitoring_user'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.user_edit === true) {
        obj.action = 'edit'
        obj.subject = 'monitoring_user'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.role_view === true) {
        obj.action = 'view'
        obj.subject = 'monitoring_role'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.role_create === true) {
        obj.action = 'create'
        obj.subject = 'monitoring_role'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }
      if (this.roles.role_edit === true) {
        obj.action = 'edit'
        obj.subject = 'monitoring_role'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }

      //validation for master data navigation
      if (this.roles.area_view === true || this.roles.checkpoint_view === true) {
        obj.action = 'view'
        obj.subject = 'master-data'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }

      //validation for settings navigation
      if (this.roles.role_view === true || this.roles.user_view === true || this.roles.guard_view === true) {
        obj.action = 'view'
        obj.subject = 'settings'
        this.ability.push(obj)
        obj = {
          action: '',
          subject: '',
        }
      }

      console.log(this.ability)
      console.log(this.userDataInfo)
      axios
        .post(`${this.$store.state.customConfig.baseURL}/web/role/edit`, {
          apikey: this.$store.state.customConfig.token,
          id: this.$route.params.id,
          name: this.userDataInfo.name,
          status: `${this.userDataInfo.status}`,
          ability: this.ability,
        }, {
          headers: { 'Access-Control-Allow-Origin': '*' },
        })
        .then(responseUser => {
          console.log(responseUser)
          this.$router.replace('/settings/roles')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Role editted',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully editted a role',
                },
              })
            })
        })
        .catch(error => {
          if (error) {
            this.isSubmitted = false
            alert("Edit Role Gagal")
          }
          // console.log(error)
          // this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
